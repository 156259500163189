import {
    faEnvelope, faHome,
    faPhoneAlt
} from '@fortawesome/free-solid-svg-icons';

const ContactInfo = [
{
    id: 1,
    icon: faEnvelope,
    name: 'Email',
    address: 'prionto71@gmail.com',
  },

  {
    id: 2,
    icon: faPhoneAlt,
    name: 'Phone',
    address: '+8801790626567',
  },
  {
    id: 3,
    icon: faHome,
    name: 'Location',
    address: 'Dhaka, Bangladesh',
  }
];

export default ContactInfo;