const AllAchievements = [
  {
    id: "01",
    imageFront: "https://i.ibb.co/Qccz0fG/ict2019-1.jpg",
    imageBack: "https://i.ibb.co/FnNt52g/ict2019-2.jpg",
    text: "Champion in Project showing, Daffodil ICT carnival 2019",
  },
  {
    id: "02",
    imageFront: "https://i.ibb.co/0C6YYMy/pro2018-1.jpg",
    imageBack: "https://i.ibb.co/4MvPQ88/pro2018-2.jpg",
    text: "1st place in Programming Camp 2018, Department of Software Engineering, PC-DIU",
  },
  {
    id: "03",
    imageFront: "https://i.ibb.co/5hD2dFK/takeoff-1.jpg",
    imageBack: "https://i.ibb.co/Mk6k6jt/takeoff-2.jpg",
    text: "4th place in DIU Takeoff Programming Contest, Spring 2018, Permanent Campus DIU",
  },
  {
    id: "04",
    imageFront: "https://i.ibb.co/ZhmkspW/cse2019-1.jpg",
    imageBack: "https://i.ibb.co/JjswpQQ/cse2019-2.jpg",
    text: "1st Runners up in Project showing, DIU CSE Fest 2019",
  },
  {
    id: "05",
    imageFront: "https://i.ibb.co/5M6SpG0/robot-1.jpg",
    imageBack: "https://i.ibb.co/KDGTR25/robot-2.jpg",
    text: "Project Management Secretary at DIU Robotics Club, DRC-PC & get a medal as a creative club",
  },
  {
    id: "06",
    imageFront: "https://i.ibb.co/b6SL9VW/waiter-1.jpg",
    imageBack: "https://i.ibb.co/c6Xcxn6/waiter-2.jpg",
    text: "Showing Project of Servant Robot for Restaurant Management System, spring 2019, DIU",
  },
];

export default AllAchievements;
