import React from 'react';

const Experiences = () => {
  const windowWidth = window.innerWidth;

  return (
    <section className="experiences" data-aos="zoom-in">
      <div className="container">
        <div className="row d-flex align-items-center flex-column">
          <div className="common mt-5">
            <h1 className="mainHeader">Working Experiences</h1>
            <div className="commonBorder" />
          </div>
          <div className="experienceDetails  row">
            <div
              className="col col2 py-5 years mr-5 px-0"
              data-aos={windowWidth > 1000 ? 'fade-right' : 'fade-up'}
              data-aos-duration="700"
              data-aos-delay="300"
            >
              <p className="text-right mt-4 firstP">SEP 2021 - CURRENT</p>
            </div>
            <div className="main-line col" />
            <div
              className="experienceInfo col col1 pt-5 pb-3 mr-2 detailsInfo"
              data-aos={windowWidth > 1000 ? 'fade-left' : 'fade-up'}
              data-aos-duration="900"
              data-aos-delay="400"
            >
              <div className="firstExp">
                <h4>Front End Developer</h4>
                <p>
                  CHAYA APP LIMITED <br />
                  <li className="point">
                    Implemented, build and deployed web applications from
                    scratch to production.
                  </li>
                  <li className="point">
                    Supervised the production and developed highly available
                    serverless technology, tested customer-facing prototypes
                    before deployment and scaling for thousands of concurrent
                    traffic.
                  </li>
                  <li className="point">
                    Strategized design choices according to user experience and
                    develop website features that can enhance the user
                    experience.
                  </li>
                  <li className="point">
                    Optimized web pages speed 2x faster and produced websites
                    compatible with multiple browsers.
                  </li>
                  <li className="point">
                    Designed and updated layouts to meet usability and
                    performance requirements.
                  </li>
                  <li className="point">
                    Implemented mobile-first responsive website approach which
                    increased mobile traffic by 20%.
                  </li>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experiences;
