import React from "react";
import bootstrap from "../images/icon/bootstrap.png";
import cProgramming from "../images/icon/c-programming.png";
import chromeDev from "../images/icon/choromeDev.png";
import css from "../images/icon/css.png";
import ecmascript6 from "../images/icon/ecmascript6.png";
import expressjs from "../images/icon/expressjs.png";
import figma from "../images/icon/figma.png";
import firebase from "../images/icon/firebase.png";
import git from "../images/icon/Git.png";
import graphQL from "../images/icon/graphQL.png";
import heroku from "../images/icon/heroku.png";
import html from "../images/icon/html.png";
import javascript from "../images/icon/js.png";
import material from "../images/icon/meta.png";
import mongodb from "../images/icon/mongodb.png";
import mongoose from "../images/icon/mongoose.png";
import mysql from "../images/icon/mysql.png";
import nextjs from "../images/icon/nextjs.png";
import nodejs from "../images/icon/nodejs.png";
import npm from "../images/icon/npm.png";
import python from "../images/icon/python.png";
import reactNative from "../images/icon/react-native.png";
import react from "../images/icon/react.png";
import reactstrap from "../images/icon/reactstrap.png";
import redux from "../images/icon/redux.png";
import sass from "../images/icon/sass.png";
import slack from "../images/icon/slack.png";
import styledComponents from "../images/icon/style-component.png";
import tailwind from "../images/icon/tailwind.png";
import trello from "../images/icon/trello.png";
import typescript from "../images/icon/type_script.png";
import vscode from "../images/icon/vs-code.png";
import webpack from "../images/icon/webpack.png";
import wordpress from "../images/icon/wordpress.png";
import yarn from "../images/icon/yarn.png";
import "./../index.css";

const Skills = () => {
  return (
    <section
      className="skills-container"
      name="skills"
      id="skills"
      data-aos="zoom-in"
    >
      <div className="common my-5 pt-5">
        <h1 className="mainHeader">What I Am Good At</h1>
        <div className="commonBorder" />
      </div>

      <div className="expertise">
        <div className="d-flex justify-content-center mb-4">
          <h3> Expertise</h3>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="mt-3">
            <img
              src={javascript}
              width="48px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-2">JavaScript</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={ecmascript6}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4 pl-2">ES 6</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={react}
              width="55px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">React.js</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={tailwind}
              width="50px"
              className="mx-4 my-4"
              alt="skill-icon"
            />
            <figcaption className="mx-3">Tailwind</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={reactstrap}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-3">Reactstrap</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={html}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-3">HTML 5</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={css}
              width="45px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4">CSS 3</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={bootstrap}
              width="70px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">Bootstrap 5</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={sass}
              width="55px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-4 pl-2">Sass</figcaption>
          </div>
        </div>
      </div>

      <div className="comfortable mt-4">
        <div className="d-flex justify-content-center mb-4 mt-5">
          <h3> Comfortable</h3>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="mt-3">
            <img
              src={redux}
              width="50px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">Redux</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={nodejs}
              width="46px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">Node.js</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={expressjs}
              width="52px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">Express.js</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={mongodb}
              width="55px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">MongoDB</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={mongoose}
              width="55px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">Mongoose</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={material}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-2 mt-4">Material UI</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={styledComponents}
              width="100px"
              className="mx-4 my-3 ml-5"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">Styled Component</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={wordpress}
              width="60px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">Wordpress</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={mysql}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">MySQL</figcaption>
          </div>
        </div>
      </div>

      <div className="familiar mt-4">
        <div className="d-flex justify-content-center mb-4 mt-5">
          <h3> Familiar</h3>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="mt-3">
            <img
              src={typescript}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-2 mt-3">TypeScript</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={nextjs}
              width="55px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">Next.js</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={reactNative}
              width="55px"
              className="mx-4 my-2 ml-5"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">React Native</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={graphQL}
              width="55px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">GraphQL</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={python}
              width="50px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-2">Python</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={cProgramming}
              width="55px"
              className="mx-4 my-2 ml-5"
              alt="skill-icon"
            />
            <figcaption className="ml-1 mt-3">C Programming</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={firebase}
              width="48px"
              className="mx-4 my-3 pl-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-4">Firebase</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={heroku}
              width="47px"
              className="mx-3 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-4">Heroku</figcaption>
          </div>
        </div>
      </div>

      <div className="tools mt-4">
        <div className="d-flex justify-content-center mb-4 mt-5">
          <h3> Tools</h3>
        </div>
        <div className="row d-flex justify-content-center align-items-center pb-3">
          <div className="mt-3">
            <img
              src={git}
              width="50px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-4 pl-2 mt-3">Git</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={trello}
              width="45px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">Trello</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={slack}
              width="60px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-4 pl-2">Slack</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={vscode}
              width="45px"
              className="mx-4 my-3"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">VS Code</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={chromeDev}
              width="50px"
              className="mx-4 my-2 ml-5"
              alt="skill-icon"
            />
            <figcaption className="mx-2 mt-3">Chrome Dev Tools</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={npm}
              width="65px"
              className="mx-4 my-4"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-4 pl-2">NPM</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={yarn}
              width="50px"
              className="mx-3 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-4 mt-3">Yarn</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={webpack}
              width="60px"
              className="mx-4 my-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-2">Webpack</figcaption>
          </div>
          <div className="mt-3">
            <img
              src={figma}
              width="35px"
              className="mx-4 my-3 pl-2"
              alt="skill-icon"
            />
            <figcaption className="mx-3 mt-3">Figma</figcaption>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
