import Aos from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import About from '../components/About';
import Achievements from '../components/Achievements';
import Blogs from '../components/Blogs';
import Contact from '../components/Contact';
import Education from '../components/Education';
import Footer from '../components/Footer';
import Home from '../components/Home';
import Nav from '../components/Nav';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Skills from '../components/Skills';
import Experiences from '../components/Experiences';
import './../index.css';

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000, offset: 200 });
  }, []);

  return (
    <div className="main-container">
      <Nav />
      <Home />
      <About />
      <Skills />
      <Portfolio />
      <Services />
      <Experiences />
      <Education />
      <Achievements />
      <Blogs />
      <Contact />
      <Footer />
    </div>
  );
}

export default Main;
