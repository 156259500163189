const AllBlogs = [
  {
    id: "01",
    title: "Redux for React: A Simple Introduction",
    field: "web-development",
    photo: "https://i.ibb.co/Ld5QWJb/x4Zxvwm.jpg",
    link: "https://prionto71.medium.com/redux-for-react-a-simple-introduction-54fb54b81b6f",
    hosting: "Medium",
  },
  {
    id: "02",
    title: "JavaScript Best Practices for Readable and Maintainable Code",
    field: "web-development",
    photo: "https://i.ibb.co/KVq8BSk/onblAzp.jpg",
    link: "https://prionto71.medium.com/javascript-best-practices-for-readable-and-maintainable-code-3ac87a2c0879",
    hosting: "Medium",
  },
  {
    id: "03",
    title: "Getting Started With Next.js and Create Your First Application",
    field: "web-development",
    photo: "https://i.ibb.co/HPHt1JB/EbK4G8J.png",
    link: "https://prionto71.medium.com/getting-started-with-next-js-and-create-your-first-application-using-next-js-9bc856307f3",
    hosting: "Medium",
  },
  {
    id: "04",
    title: "React Important Topics with Interview Questions",
    field: "web-development",
    photo: "https://i.ibb.co/Drn8dSc/9ClZ7zU.jpg",
    link: "https://prionto71.medium.com/react-interview-questions-8a435ea43812",
    hosting: "Medium",
  },
  {
    id: "05",
    title: "Most useful JavaScript methods You must need to know",
    field: "web-development",
    photo: "https://i.ibb.co/S3Gvy6n/VjYpyMq.png",
    link: "https://prionto71.medium.com/most-useful-javascript-methods-you-must-need-to-know-f769df792e3",
    hosting: "Medium",
  },
  {
    id: "06",
    title: "Common HTML and CSS Interview Questions and Answers",
    field: "web-development",
    photo: "https://i.ibb.co/1nrYpDh/VPB9W0O.jpg",
    link: "https://prionto71.medium.com/common-html-and-css-interview-questions-56c1b9d59aed",
    hosting: "Medium",
  },
];

export default AllBlogs;
