import {
  faDesktop,
  faLaptopCode,
  faLaptopHouse,
} from "@fortawesome/free-solid-svg-icons";

const AllServices = [
  {
    id: 1,
    icon: faLaptopCode,
    name: "Front End Web App",
    details:
      "I can make a Fully responsive front end web application with react and next.js for server side rendering with Tailwind",
  },

  {
    id: 2,
    icon: faDesktop,
    name: "E-commerce Web App",
    details:
      "I will build e-commerce web application with React, Redux, MongoDB and SSLCOMMERZ Payment Gateway",
  },
  {
    id: 3,
    icon: faLaptopHouse,
    name: "Full Stack Web App",
    details:
      "Using MERN Stack I usually make Full Stack Web Application and using oAuth2, passport.js and Firebase store",
  },
];

export default AllServices;
