import React from "react";
import Fade from "react-reveal/Fade";
import AllAchievements from "../fakeData/AllAchievements";
import "./../index.css";

const Achievements = () => {
  return (
    <section
      data-aos="fade-up"
      className="achievements-container"
      name="achievements"
      id="achievements"
    >
      <div className="common mt-5">
        <h1 className="mainHeader">Achievements</h1>
        <div className="commonBorder" />
      </div>

      <div className="projects-container">
        <Fade bottom>
          {AllAchievements.map((item) => (
            <div className="projects" key={item.id}>
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img
                      src={item.imageFront}
                      alt="Achievement"
                      className="Achievement-images"
                    />
                  </div>
                  <div className="flip-box-back">
                    <img
                      src={item.imageBack}
                      alt="Achievement"
                      className="Achievement-images"
                    />
                  </div>
                </div>
              </div>

              <div className="project-links mt-2">
                <h3>{item.text}</h3>
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </section>
  );
};

export default Achievements;
